@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css?family=News+Cycle&display=swap');

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  padding: 10px 8px 0px;
  height: 100vh;
}

body,
html {
  margin: 0px;
  padding: 0px 0px 0px;
  font-size: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  width: 90%;
  align-self: center;
  height: auto;
}

h1 {
  color: black;
  margin: 0;
  font-family: 'Cormorant Garamond';
  font-weight: 300;
  font-size: 3em;
  letter-spacing: 0.1rem;
  /* white-space: nowrap; */
}

h2 {
  margin: 0;
  font-family: 'News Cycle';
  font-size: 1.5em;
  color: #342f2f;
  letter-spacing: 0.2rem;
}

.body-header {
  font-family: 'Cormorant Garamond';
  font-size: 2.4em;
  color: black;
  background-color: white;
  margin-bottom: 4px;
}

p {
  font-family: 'News Cycle';
  font-size: 1.2em;
  margin: 0px 0px 10px 0px;
}

.content-container {
  padding: 30px 60px;
  display: flex;
  background: rgb(189, 203, 253, 0.3);
  overflow: hidden;
}

.home-container p {
  text-align: center;
}

.bio-content-container {
  flex-direction: column;
  margin-bottom: 100px;
}

.bio-content-container ul {
  list-style-type: '- ';
  list-style-position: inside;
  padding-left: 0;
  margin: 0;
}

.bio-content-container li {
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 1.2em;
}

.areas-content-container {
  padding: 30px 16px;
}

.contact-content-container {
  justify-content: center;
  margin-bottom: 200px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  border-top: solid black 1px;
  margin-left: 20px;
  max-width: 600px;
}

.navbar ul {
  list-style-type: none;
  margin-top: 0;
  overflow: hidden;
  padding: 0px;
}

.navbar li {
  float: left;
  padding: 10;
}

.navbar li a {
  display: block;
  padding: 0px 12px;
  text-decoration: none;
  color: black;
  margin-top: 5px;
}

.navbar li > a:hover {
  color: #555;
}

/* perhaps the boxes can be done on a grid instead of with a flexbox */
.box-container {
  display: 'flex';
  flex-direction: 'column';
  justify-items: 'space-between';
  height: 5em;
}

#boxOne {
  display: 'flex';
  height: 1em;
  width: 1em;
  background-color: #bdcbfd;
}

#boxTwo {
  display: 'flex';
  height: 1em;
  width: 1em;
  background-color: #1a1d39;
}

#boxThree {
  display: 'flex';
  height: 1em;
  width: 1em;
  background-color: black;
}

footer {
  background-color: #1a1d39;
  color: white;
  padding: 8px;
  width: 100%;
  margin-left: -8px;
}

footer p {
  font-family: 'News Cycle';
  text-align: center;
  font-size: 1.2em;
  margin: 0;
}

footer a {
  font-size: 1.2em;
  color: white;
  font-family: 'News Cycle';
}

.dot {
  height: 6px;
  width: 6px;
  background-color: white;
  border-radius: 50%;
  margin: 0px 6px;
  display: inline-block;
  align-self: center;
}

@media only screen and (min-width: 1200px) {
  .bio-content-container {
    margin-bottom: 200px;
  }
}

@media only screen and (max-width: 600px) {
  .content-container {
    padding: 30px;
  }
  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.2em;
  }

  p {
    font-size: 1em;
  }

  footer p {
    font-size: 0.9em;
  }

  footer a {
    font-size: 0.9em;
  }

  .body-header {
    font-size: 2em;
  }

  .bio-content-container li {
    font-size: 0.9em;
  }
  .contact-content-container {
    margin-bottom: 0px;
  }
}
